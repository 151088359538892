import { Box, Flex, Text, Image, Progress, HStack, Badge, Tooltip } from "@chakra-ui/react";
import { Rank as RankType, XpType } from "../../rest/kairos/types";
import { InfoIcon } from "@chakra-ui/icons";
import { getColorForXpType, getProgress } from "../../helpers/xp";

interface Props {
    rank: RankType;
}

export const Rank = ({ rank }: Props) => {
    const getRequirementText = (type: XpType) => {
        if (rank.rank.requirements.find(req => req.type === type)) {
            return `${type}: ${rank[type]} / ${rank.rank.requirements.find(req => req.type === type)?.amount}`;
        }

        if (rank[type] === 0) {
            return `${type}: 0`;
        }

        return `${type}: +${rank[type]}`;
    };

    return (
        <Box bg="#18181b" p={8} className="mint-box" maxW="100%">
            <Flex alignItems="center" gap={8}>
                <Box flexShrink={0}>
                    <Image
                        src={`/kyros/${rank.rank.title.toLowerCase()}.png`}
                        boxSize="50px"
                        alt={rank.rank.title}
                    />
                </Box>
                <Box>
                    <Text
                        fontSize="lg"
                        fontWeight="semibold"
                        color="white"
                        mb={1}
                    >
                        {rank.rank.title}
                    </Text>
                    <Text
                        fontSize="md"
                        color="whiteAlpha.800"
                    >
                        {rank.rank.description}
                    </Text>
                </Box>
            </Flex>
            <Flex justifyContent="space-between" mt={4}>
                <Box width="100%">
                    <Text fontSize="lg" fontWeight="semibold" color="white" textAlign="left" mb={4}>
                        XP: {rank.xp} / {rank.rank.maxXp}
                    </Text>
                    <Progress
                        value={getProgress(rank.xp, rank.rank.minXp, rank.rank.maxXp)}
                        size="lg"
                        width="100%"
                        height="5px"
                        colorScheme="gray"
                        sx={{
                            '& > div': {
                                backgroundColor: '#5EF4F3'
                            },
                            backgroundColor: '#2D2D2D'
                        }}
                        mb={4}
                    />
                    <HStack spacing={4} mb={4}>
                        <Badge colorScheme={getColorForXpType(XpType.Attack)}>{getRequirementText(XpType.Attack)}</Badge>
                        <Badge colorScheme={getColorForXpType(XpType.Defense)}>{getRequirementText(XpType.Defense)}</Badge>
                        <Badge colorScheme={getColorForXpType(XpType.Loyalty)}>{getRequirementText(XpType.Loyalty)}</Badge>
                        <Tooltip hasArrow={false}label='Level up your skills, this will help you become a better warrior.' bg='whiteAlpha.300' color='white'>
                            <InfoIcon />
                        </Tooltip>
                    </HStack>
                </Box>
            </Flex>
        </Box>
    );
}