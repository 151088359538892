import { useContext, useState } from 'react';
import { getAccount, TOKEN_PROGRAM_ID } from "@solana/spl-token";
import { PublicKey } from "@solana/web3.js";
import { useConnection } from '@solana/wallet-adapter-react';
import mixpanel from 'mixpanel-browser';
import { AppConfigContext } from '../context';

export const useBalance = (token: PublicKey) => {
  const { connection } = useConnection();
  const { vrtMintAddress } = useContext(AppConfigContext);

  const [balance, setBalance] = useState<string | null>("0");
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const fetchBalance = async (publicKey: PublicKey | null) => {
    setIsLoading(true);
    setError(null);

    try {
      if (!publicKey) {
        setError('Wallet not connected');
        return;
      }

      const tokenAccounts = await connection.getTokenAccountsByOwner(publicKey, {
        mint: token,
        programId: TOKEN_PROGRAM_ID,
      });

      if (tokenAccounts.value.length > 0) {
        const tokenAccount = tokenAccounts.value[0];
        const accountInfo = await getAccount(connection, new PublicKey(tokenAccount.pubkey));
        setBalance((Number(accountInfo.amount) / 1e9).toString());

        if (vrtMintAddress === token.toBase58() && balance && balance !== '0') {
          mixpanel.people.set({
            'kysol_balance': balance
          });
        }
      } else {
        setBalance('0');
      }
    } catch (error) {
      console.error('Error fetching token balance:', error);
      setBalance('0');
    } finally {
      setIsLoading(false);
    }
  };

  return { balance, isLoading, error, fetchBalance };
};