import { Flex, Text } from "@chakra-ui/react";
import { ThemeColor } from "../../theme/palette";
import { Link } from "../Link";
import { getAlphaColor } from "../../theme/utils";
import mixpanel from "mixpanel-browser";

export const Notice = () => {
    return (
        <Flex
            color={ThemeColor.KAIROS}
            bg={getAlphaColor(ThemeColor.KAIROS as string, 0.1)}
            px={"0rem"}
            py={"1.5rem"}
            alignItems={"center"}
            sx={{
                a: {
                    color: getAlphaColor(ThemeColor.KAIROS as string, 1),
                    fontWeight: "bold",
                },
                "a:hover": {
                    color: getAlphaColor(ThemeColor.KAIROS as string, 0.6),
                },
            }}
            flexDirection={"column"}
        >
            <Text style={{ textAlign: "center" }}>
                <b>Phase 2</b> begins on Monday 18/11 at 1330 UTC. Enable notifications on <Link to={"https://twitter.com/KyrosFi"} onClick={() => {
                    mixpanel.track('Notice Link Click', {
                        'destination': 'twitter'
                    });
                }}>X</Link> to stay updated and not miss it!
            </Text>
        </Flex>
    );
};