import { Table, TableProps, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { Opportunity } from "../../../rest/kairos/types";
import { Link } from "../../Link";

interface Props extends TableProps {
    data: Opportunity[];
}

export const OpportunitiesTable = ({ data, ...props }: Props) => {
    return (
        <Table>
            <Thead>
                <Tr>
                    <Th>Protocol</Th>
                    <Th>Network</Th>
                    <Th>Multiplier</Th>
                    <Th>TVL</Th>
                    <Th textAlign={"end"} display={{ base: "none", xl: "table-cell" }}>
                        Link
                    </Th>
                </Tr>
            </Thead>
            <Tbody>
                {data.map(({ protocol, network, multiplier, tvl, link }, index) => (
                    <Tr key={`${protocol}${index}`}>
                        <Td textColor={"white"}>{protocol}</Td>
                        <Td textColor={"white"}>{network}</Td>
                        <Td textColor={"white"}>{multiplier}x</Td>
                        <Td textColor={"white"}>$ {tvl.toLocaleString()}</Td>
                        <Td textColor={"white"} textAlign={"end"} display={{ base: "none", xl: "table-cell" }}>
                            <Link to={link} isExternal>
                                {link}
                            </Link>
                        </Td>
                    </Tr>
                ))}
            </Tbody>
        </Table>
    );
}