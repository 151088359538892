import { useState, useEffect, useContext } from 'react';
import { AppConfigContext } from '../context';

export const useKyrosApy = () => {
    const { apiUrl } = useContext(AppConfigContext);
    const [apy, setApy] = useState<number | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    const fetchApy = async () => {
        setIsLoading(true);
        setError(null);
        try {
            const response = await fetch(`${apiUrl}/jito/apy`);
            const data = await response.json();
            if (data && data.apy && data.apy !== "NaN") {
                setApy(Number(data.apy));
            }
        } catch (err) {
            console.error("Error fetching APY:", err);
            setApy(null);
            setError("Error fetching APY");
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchApy();
        const interval = setInterval(fetchApy, 60000);
        return () => clearInterval(interval);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return { apy, isLoading, error };
};
