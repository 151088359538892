import { Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Box, Heading } from "@chakra-ui/react"

interface FAQProps {
    questions: {
        question: string
        answer: React.ReactNode
    }[]
}

export const FAQ = ({ questions }: FAQProps) => {
    return (
        <Box mt={8}>
            <Heading as="h3" fontSize="xl" mb={4} color="white">
                FAQs
            </Heading>
            <Accordion allowMultiple>
                {questions.map((question, index) => (
                    <AccordionItem key={index}>
                        <h2>
                            <AccordionButton>
                                <Box flex="1" textAlign="left">
                                    {question.question}
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                        </h2>
                        <AccordionPanel pb={4}>
                            {question.answer}
                        </AccordionPanel>
                    </AccordionItem>
                ))}
            </Accordion>
        </Box>
    )
}