import { XpType } from "../rest/kairos/types";

export const getProgress = (xp: number, minXp: number, maxXp: number) => {
    return (xp - minXp) / (maxXp - minXp) * 100;
}

export const getColorForXpType = (type: XpType): string => {
    switch (type) {
        case XpType.Attack:
            return "red";
        case XpType.Defense:
            return "blue";
        case XpType.Loyalty:
            return "yellow";
        default:
            return "green";
    }
};