import {
  Badge,
  Box,
  Button,
  Container,
  Flex,
  VStack,
  Text,
  Input,
  InputLeftAddon,
  InputGroup,
  Image,
  HStack,
  Link,
  Portal,
  MenuButton,
  MenuList,
  MenuItem,
  Menu,
} from "@chakra-ui/react";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import { PublicKey } from "@solana/web3.js";
import { useFormik } from "formik";
import { useEffect, useContext, useState } from "react";
import { useErrorToast, useSuccessToast } from "../helpers/toast";
import { AppConfigContext } from "../context";
import { useBalance } from "../hooks/useBalance";
import { ArrowDownIcon, ChevronDownIcon } from "@chakra-ui/icons";
import { useMintTo } from "../hooks/useMintTo";
import { useWalletModal } from "@solana/wallet-adapter-react-ui";
import { TVL } from "../components";
import { useKyrosApy } from "../hooks/useKyrosApy";
import { useTotalSupply } from "../hooks/useTotalSupply";
import { CTA, FAQ } from "../components";
import "../styles/MintBox.css";
import { useJitoSOLRate } from "../hooks/useJitoSOLRate";
import { useNativeBalance } from "../hooks/useNativeBalance";

export const MintPage = () => {
  const { apy } = useKyrosApy();

  const { publicKey } = useWallet();
  const { connection } = useConnection();
  const { vrtMintAddress, jitoSolMintAddress, vaultMaxCap, vaultPaused } = useContext(AppConfigContext);

  const [selectedToken, setSelectedToken] = useState<string>("jitosol");
  const [displayedBalance, setDisplayedBalance] = useState<string>("0 JitoSOL");
  const [formDisabled, setFormDisabled] = useState<boolean>(false);
  const [capReached, setCapReached] = useState<boolean>(false);

  const { balance: jitoSolBalance, fetchBalance: fetchJitoSolBalance } = useBalance(new PublicKey(jitoSolMintAddress));
  const { fetchBalance: fetchKySolBalance } = useBalance(new PublicKey(vrtMintAddress));
  const { balance: solBalance, fetchNativeBalance } = useNativeBalance();
  const { totalSupply, isLoading: isTotalSupplyLoading, error: totalSupplyError, fetchTotalSupply } = useTotalSupply();
  const { errorToast } = useErrorToast();
  const { successToast } = useSuccessToast();
  const { mintTo, error: mintToError } = useMintTo();

  const estimatedJitoSolRate = 1.148;
  const { rate: jitoSolRate, fetchRate } = useJitoSOLRate();

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;

    let receivedValue = inputValue;
    if (selectedToken === "sol") {
      receivedValue = inputValue ? `≈ ${(Number(inputValue) * (jitoSolRate ?? estimatedJitoSolRate)).toFixed(9)}` : "";
    }

    formik.setValues({
      amount: inputValue,
      receivedAmount: receivedValue
    });
  };

  const handleMaxClick = () => {
    const maxBalance = selectedToken === "sol"
      ? solBalance?.toString() || "0"
      : jitoSolBalance?.toString() || "0";

    // Get max amount considering vault cap
    const remainingCap = vaultMaxCap - (Number(totalSupply) || 0);
    const maxAmount = Math.min(Number(maxBalance), remainingCap).toFixed(9);

    // Calculate received amount based on selected token
    const receivedAmount = selectedToken === "sol"
      ? `≈ ${(Number(maxAmount) * (jitoSolRate ?? estimatedJitoSolRate)).toFixed(9)}`
      : maxAmount;

    formik.setValues({
      amount: maxAmount,
      receivedAmount: receivedAmount
    });
  };

  const formik = useFormik({
    initialValues: {
      amount: "",
      receivedAmount: "",
    },
    onSubmit: async (values) => {
      try {
        const signature = await mintTo(values.amount, selectedToken === "sol");
        if (signature) {
          successToast("Your mint was successful!");
          reload();
        }
      } catch (err) {
        errorToast(`${err}`);
      }
    },
  });

  useEffect(() => {
    if (mintToError) {
      errorToast(mintToError);
      formik.setSubmitting(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mintToError]);

  const reload = () => {
    formik.resetForm();
    fetchJitoSolBalance(publicKey);
    fetchKySolBalance(publicKey);
    fetchNativeBalance(publicKey);
    fetchTotalSupply();
    fetchRate(connection);
  };

  useEffect(() => {
    setDisplayedBalance(
      selectedToken === "sol"
        ? solBalance?.toString() + " SOL"
        : jitoSolBalance?.toString() + " JitoSOL"
    );
  }, [jitoSolBalance, solBalance, selectedToken]);

  useEffect(() => {
    setFormDisabled(
      selectedToken === "sol"
        ? formik.values.amount > (solBalance?.toString() || "0")
        : formik.values.amount > (jitoSolBalance?.toString() || "0")
    );
  }, [formik.values.amount, jitoSolBalance, solBalance, selectedToken]);

  useEffect(() => {
    fetchJitoSolBalance(publicKey);
    fetchKySolBalance(publicKey);
    fetchRate(connection);
    fetchNativeBalance(publicKey);
    fetchTotalSupply();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicKey]);

  useEffect(() => {
    const supply = Number(totalSupply);
    const receivedAmount = Number(formik.values.receivedAmount.replace(/[^0-9.]/g, ''));
    setFormDisabled(supply > vaultMaxCap || (supply + receivedAmount) > vaultMaxCap);
  }, [vaultMaxCap, totalSupply, formik.values.receivedAmount]);

  useEffect(() => {
    if (totalSupply) {
      setCapReached(Number(totalSupply) >= vaultMaxCap * 0.99999);
    }
  }, [totalSupply, vaultMaxCap]);

  const { setVisible } = useWalletModal();
  const { connecting } = useWallet();

  return (
    <>
      <Flex direction="column" minHeight="100vh">
        <Container
          maxW="520px"
          flex="1"
          py={8}
          display="flex"
          justifyContent="center"
        >
          <VStack spacing={8} align="stretch">
            <Flex alignItems="center" justifyContent="flex-start">
              <Text
                fontWeight="bold"
                fontSize="2xl"
                color="white"
                lineHeight="1.2"
                mr={2}
              >
                Get kySOL
              </Text>
              { apy !== null && (
                <Badge
                  colorScheme="green"
                borderRadius="full"
                px={4}
                py={1}
                fontSize="md"
                display="flex"
                alignItems="center"
                height="auto"
                >
                  ≈ {(apy * 100).toFixed(2)}% APY
                </Badge>
              )}
            </Flex>

            <Text fontSize="md" fontWeight="400" color="#CCCCCC">
              kySOL combines staking, MEV, and restaking rewards into one token for optimized returns.
            </Text>
            <TVL totalSupply={totalSupply} isTotalSupplyLoading={isTotalSupplyLoading} totalSupplyError={totalSupplyError} />

            <Flex justifyContent="space-between" alignItems="stretch" gap={4}>
              <CTA
                destination="https://app.kamino.finance/liquidity/5ESAmxob1RsTrMVt7oaqvyDVoo4eD1Ns7s2xWiK4RjMK"
                destinationTracking="kamino"
                image="/kmno.png"
                title="Provide liquidity"
                description="Add some kySOL / JitoSOL LP on Kamino"
              />
              <CTA
                destination="https://jup.ag/swap/SOL-kySo1nETpsZE2NWe5vj2C64mPSciH1SppmHb4XieQ7B"
                destinationTracking="jupiter"
                image="/kySOLLogo.svg"
                title="Buy kySOL"
                description="Swap any token to kySOL on Jupiter"
              />
            </Flex>

            <Box className="mint-box">
              <Flex
                justifyContent="space-between"
                alignItems="center"
                mb={4}
                mt={4}
              >
                <Text fontSize="md" fontWeight="semibold" color="white">
                  (RE)STAKE
                </Text>
                {publicKey && (
                  <HStack spacing={2} alignItems="center">
                    <Image src="/wallet-icon.svg" boxSize="16px" />
                    <Text fontSize="sm" fontWeight="light" color="white">
                      {displayedBalance}
                    </Text>
                    <Button
                      size="sm"
                      onClick={handleMaxClick}
                      variant="ghost"
                      color="whiteAlpha.800"
                      _hover={{ bg: "whiteAlpha.200" }}
                      backgroundColor="whiteAlpha.300"
                      fontWeight="normal"
                      paddingX={2}
                      isDisabled={capReached}
                    >
                      MAX
                    </Button>
                  </HStack>
                )}
              </Flex>

              <form onSubmit={formik.handleSubmit}>
                <InputGroup
                  mb={4}
                  sx={{
                    borderRadius: "8px",
                    borderColor: "gray.500",
                  }}
                >

                  <InputLeftAddon
                    p={0}
                    sx={{
                      color: "white.200",
                      paddingX: "12px",      // Match padding with input box
                      fontSize: "xl",        // Match font size
                      height: "60px",        // Match height with input box
                      display: "flex",
                      alignItems: "center",  // Center the text vertically
                      borderWidth: "0.1px",
                      borderColor: "#403D3B",
                      _hover: { color: "white.300" },
                      width: "150px",        // Fixed width
                    }}
                  >
                    <Menu placement="bottom">
                      <MenuButton
                        as={Button}
                        variant="unstyled" // Remove theme styling
                        borderRadius={0}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center", // Center content horizontally
                          fontWeight: "bold",
                          color: "white",
                          padding: 0,        // Remove extra padding
                          height: "60px",    // Match the height
                          zIndex: 1,         // Ensure the button is above other elements
                          width: "100%",     // Occupy full width of the parent (130px)
                          role: "group" // Enables group hover
                        }}
                      >
                        <Box
                          display="flex"
                          alignItems="center"
                          width="100%"              // Occupy full width
                          justifyContent="center"   // Center content horizontally
                        >
                          <Image
                            src={selectedToken === "sol" ? "/solana.png" : "/jitosol.png"}
                            boxSize="25px"
                            borderRadius="full"
                          />
                          <Text fontWeight="bold" ml={1} color="white">
                            {selectedToken === "sol" ? "SOL" : "JitoSOL"}
                          </Text>
                          <ChevronDownIcon
                            ml={1}
                            color="white"
                            transition="all 0.3s ease" // Adds smooth transition
                            _groupHover={{
                              color: "#5DF0EE", // Example color change
                            }}
                          />
                        </Box>
                      </MenuButton>
                      <Portal>
                        <MenuList
                          zIndex={1000} // Set a high zIndex to ensure visibility
                          left="50%"    // Center horizontally
                          transform="translateX(-50%)"
                          backgroundColor="#18181b"
                        >
                          <MenuItem
                            key={"sol"}
                            onClick={() => {
                              setSelectedToken("sol");
                              // Recalculate received amount when switching to SOL
                              const newReceivedAmount = formik.values.amount
                                ? `≈ ${(Number(formik.values.amount) * (jitoSolRate ?? estimatedJitoSolRate)).toFixed(9)}`
                                : "";
                              formik.setValues({
                                ...formik.values,
                                receivedAmount: newReceivedAmount
                              });
                            }}
                            margin={0}
                            backgroundColor="#18181b"
                          >
                            <Box display="flex" alignItems="center">
                              <Image src={"/solana.png"} boxSize="24px" mr="2" />
                              SOL
                            </Box>
                          </MenuItem>
                          <MenuItem
                            key={"jitosol"}
                            onClick={() => {
                              setSelectedToken("jitosol");
                              // When switching to jitoSOL, received amount equals input amount
                              formik.setValues({
                                ...formik.values,
                                receivedAmount: formik.values.amount
                              });
                            }}
                            margin={0}
                            backgroundColor="#18181b"
                          >
                            <Box display="flex" alignItems="center" mr="2">
                              <Image src={"/jitosol.png"} boxSize="24px" mr="2" />
                              JitoSOL
                            </Box>
                          </MenuItem>
                        </MenuList>
                      </Portal>
                    </Menu>
                  </InputLeftAddon>
                  
                  {/* <InputLeftAddon
                    children={
                      <Box display="flex" alignItems="center">
                        <Image
                          src="/jitosol.png"
                          boxSize="30px"
                          ml={2}
                          borderRadius="full"
                          border="2px solid #222F44"
                        />
                        <Text fontWeight="bold" ml={2} color="white">JitoSOL</Text>
                      </Box>
                    }
                    sx={{
                      color: "white.200",
                      paddingX: "12px",      // Match padding with input box
                      fontSize: "md",        // Match font size
                      height: "60px",        // Match height with input box
                      display: "flex",
                      alignItems: "center",  // Center the text vertically
                      borderWidth: "0.1px",
                      borderColor: "#403D3B",
                      width: "150px",
                    }}
                  /> */}

                  <Input
                    id="amount"
                    type="number"
                    placeholder="0.0"
                    value={formik.values.amount}
                    onChange={handleInputChange}
                    color="white"
                    sx={{
                      width: "100%",
                      height: "60px",
                      fontSize: "md",
                      borderColor: "#403D3B",
                      borderWidth: "0.1px",
                      textAlign: "right",
                      _placeholder: { color: "#595959" },
                    }}
                  />

                </InputGroup>

                <Flex justifyContent="center" alignItems="center">
                  <ArrowDownIcon color="grey" boxSize={6} />
                </Flex>

                <Flex justifyContent="space-between" alignItems="center" mb={4}>
                  <Text fontSize="md" fontWeight="semibold" color="white">
                    TO RECEIVE
                  </Text>
                  <Text fontSize="xs" fontWeight="light" color="#9A9998">
                    0% Slippage
                  </Text>
                </Flex>

                <InputGroup
                  mb={4}
                  sx={{
                    borderRadius: "8px",
                    borderColor: "gray.500",
                  }}
                >
                  <InputLeftAddon
                    children={
                      <Box display="flex" alignItems="center">
                        <Image
                          src="/kySOLLogo.svg"
                          boxSize="30px"
                          ml={2}
                          borderRadius="full"
                          border="2px solid #222F44"
                        />
                        <Text fontWeight="bold" ml={2} color="white">kySOL</Text>
                      </Box>
                    }
                    sx={{
                      color: "white.200",
                      paddingX: "12px",      // Match padding with input box
                      fontSize: "md",        // Match font size
                      height: "60px",        // Match height with input box
                      display: "flex",
                      alignItems: "center",  // Center the text vertically
                      borderWidth: "0.1px",
                      borderColor: "#403D3B",
                      width: "150px",
                    }}
                  />
                  <Input
                    id="receive"
                    placeholder="0.0"
                    value={formik.values.receivedAmount}
                    readOnly
                    color="white"
                    sx={{
                      width: "100%",        // Makes the input take up full width within InputGroup
                      height: "60px",       // Set height to match addon
                      fontSize: "md",       // Set font size to match addon
                      borderColor: "#403D3B",
                      borderWidth: "0.1px",
                      textAlign: "right",     // Aligns the text to the right
                      _placeholder: { color: "595959" },
                    }}
                  />
                </InputGroup>


                {!publicKey ? (
                  <Flex justifyContent="center" width="100%">
                    <Button
                      width="100%"
                      className="mint-box-button"
                      isDisabled={connecting}
                      onClick={() => setVisible(true)}
                    >
                      {connecting ? "Connecting..." : "Connect Wallet"}
                    </Button>
                  </Flex>
                ) : (
                  <Flex justifyContent="center" width="100%">
                    <Button width="100%" className="mint-box-button" isDisabled={formDisabled || vaultPaused || capReached} isLoading={formik.isSubmitting} type="submit">
                      {capReached ? "Cap filled" : "Convert to kySOL"}
                    </Button>
                  </Flex>
                )}
              </form>

              <Flex className="exchange-info" mt={4}>
                <Text>Exchange rate:</Text>
                <Text ml={2}>{selectedToken === "sol" ? `1 SOL = ${jitoSolRate?.toFixed(4) ?? (1 / estimatedJitoSolRate).toFixed(4)} kySOL` : `1 JitoSOL = 1 kySOL`}</Text>
              </Flex>
            </Box>

            <FAQ questions={[
              { question: "What is Kyros?", answer: <Text>Kyros is a liquid restaking protocol built on Jito (Re)staking, offering uncapped yield through kySOL, a yield-bearing token on Solana that combines staking rewards, MEV rewards, and restaking rewards into a single token.</Text> },
              { question: "What is kySOL?", answer: <Text>kySOL represents your deposit of JitoSOL in our VRT vault, which supports the security of Node Consensus Networks (NCNs) while providing additional rewards. With kySOL, you earn Solana staking rewards, MEV rewards, and restaking rewards—all in a single token.</Text> },
              { question: "How to (Re)stake?", answer: <Text>Restaking is as simple as minting kySOL. When you deposit your staked assets into our vault, we delegate them to industry-leading node operators who validate transactions for NCNs. Our chosen node operators have extensive experience and a strong track record in network security and efficiency.</Text> },
              { question: "How to (Un)stake?", answer: <Text>You can unstake through the website, with a waiting period of at least one epoch (~2 days) to receive your JitoSOL back. Alternatively, you can swap kySOL for JitoSOL instantly on <Link color='#5EF4F3' href='https://jup.ag/swap/kySo1nETpsZE2NWe5vj2C64mPSciH1SppmHb4XieQ7B-JitoSOL' isExternal>Jupiter</Link>.</Text> },
              { question: "Who are the NCNs to which you delegate?", answer: <Text>Kyros will delegate to the Jito TipRouter NCN upon its launch and plans to integrate additional NCNs later through a dedicated process combining on-chain governance and risk curation.</Text> },
              { question: "Who are the node operators?", answer: <Text>Initially, Kyros will partner with Kiln, Luganodes, Helius, and Temporal as our node operators. You can learn more about them in our <Link color='#5EF4F3' href='https://docs.kyros.io/' isExternal>documentation</Link>.</Text> },
            ]} />

          </VStack >
        </Container >
      </Flex >
    </>
  );
};
