import { Container, Flex, Heading, VStack } from "@chakra-ui/react";
import { DeFiTable } from "../components/DeFiTable";

export const DeFiPage = () => {
  return (
    <>
      <Flex direction="column" minHeight="100vh">
        <Container maxW="container.xl" flex="1" py={8}>
          <VStack spacing={8} align="stretch">
            <Heading as="h1" color={"white"}>kySOL Ecosystem,</Heading>
            <Heading as="h1">DeFi Opportunities</Heading>
            <DeFiTable />
          </VStack>
        </Container>
      </Flex>
    </>
  );
};
