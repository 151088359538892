export enum ThemeColor {
    ACTION_PRIMARY = "actionPrimary",
    ACTION_PRIMARY_HOVER = "actionPrimaryHover",
    ACTION_CONFIRMATION = "actionConfirmation",
    ACTION_WARNING = "actionWarning",
    ACTION_ERROR = "actionError",
    BACKGROUND = "background",
    CONTENT = "content",
    CARDS = "cards",
    GENERAL = "general",
    KAIROS = "kairos",
    LABEL = "label",
    LINES = "lines",
    MID_RANGE = "midRange",
    CRYPTO_AVAX = "cryptoAvax",
    CRYPTO_BORG = "cryptoBorg",
    CRYPTO_BNB = "cryptoBnb",
    CRYPTO_BTC = "cryptoBtc",
    CRYPTO_ETH = "cryptoEth",
    CRYPTO_MATIC = "cryptoMatic",
    CRYPTO_MORPHO = "cryptoMorpho",
    CRYPTO_SAVAX = "cryptoSavax",
    CRYPTO_USDC = "cryptoUsdc",
    CRYPTO_USDT = "cryptoUsdt",
}

export const palette = {
    [ThemeColor.ACTION_PRIMARY]: "#3a88e3",
    [ThemeColor.ACTION_PRIMARY_HOVER]: "#347acd",
    [ThemeColor.ACTION_CONFIRMATION]: "#5ec88f",
    [ThemeColor.ACTION_WARNING]: "#ffac30",
    [ThemeColor.ACTION_ERROR]: "#ff5d53",
    [ThemeColor.BACKGROUND]: {
        _light: "#2b4162",
        _dark: "linear-gradient(315deg, #2b4162 0%, #12100e 74%)",
    },

    [ThemeColor.CONTENT]: {
        _light: "#1a202c",
        _dark: "#edf2f7",
    },
    [ThemeColor.CARDS]: {
        _light: "#ffffff",
        _dark: "#18181b",
    },
    [ThemeColor.GENERAL]: {
        _light: "#edf2f7",
        _dark: "#2d3748",
    },
    [ThemeColor.KAIROS]: "#5FF4F4",
    [ThemeColor.LABEL]: {
        _light: "#000000",
        _dark: "#ffffff",
    },
    [ThemeColor.LINES]: {
        _light: "#e2e8f0",
        _dark: "#2d3748",
    },
    [ThemeColor.MID_RANGE]: {
        _light: "#cbd5e0",
        _dark: "#4a5568",
    },
    [ThemeColor.CRYPTO_AVAX]: "#d74f49",
    [ThemeColor.CRYPTO_BORG]: "#02c38d",
    [ThemeColor.CRYPTO_BNB]: "#f0b90b",
    [ThemeColor.CRYPTO_BTC]: "#f7931a",
    [ThemeColor.CRYPTO_ETH]: "#627eea",
    [ThemeColor.CRYPTO_MATIC]: "#8247e5",
    [ThemeColor.CRYPTO_MORPHO]: "#2470FF",
    [ThemeColor.CRYPTO_SAVAX]: "#04B5ED",
    [ThemeColor.CRYPTO_USDC]: "#2775ca",
    [ThemeColor.CRYPTO_USDT]: "#009293",
} as const;
