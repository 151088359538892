import { Text } from "@chakra-ui/react";
import { useWallet } from "@solana/wallet-adapter-react";
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import { AppConfigContext } from "../../context";
import { useContext } from "react";

export const CustomWalletMultiButton: React.FC = () => {
    const { wallet, publicKey, connecting } = useWallet();
    const { apiUrl } = useContext(AppConfigContext)

    const formatPublicKey = (key: string) => {
        return `${key.slice(0, 4)}..${key.slice(-4)}`;
    };

    const logConnection = () => {
        if (publicKey) {
            fetch(`${apiUrl}/kyros/login?wallet=${publicKey.toBase58()}`, {
                method: 'POST'
            }).catch(err => console.error('Login error:', err));
        }
    }

    let content;

    if (connecting) {
        content = 'Connecting';
    } else if (!wallet || !publicKey) {
        content = 'Connect Wallet';
    } else {
        const formattedKey = formatPublicKey(publicKey.toBase58());
        logConnection();
        content = (
            <Text>{formattedKey}</Text>
        );
    }

    return (
        <div className="wallet-connect">
            <WalletMultiButton>
                {content}
            </WalletMultiButton>
        </div>
    );
};
