import { useToast } from '@chakra-ui/react'

export function useErrorToast() {
  const toast = useToast()
  function errorToast(message: string) {
    toast({
      title: 'Alas!',
      description: message,
      status: 'error',
      duration: 5000,
      isClosable: true,
    })
  }

  return { errorToast }
}

export function useSuccessToast() {
  const toast = useToast()
  function successToast(message: string) {
    toast({
      title: 'Huzzah!',
      description: message,
      status: 'success',
      duration: 5000,
      isClosable: true,
    })
  }
  return { successToast }
}