export function createMintToPayload(amountIn: number, minAmountOut: number): Buffer {
  const instructionByte = new Uint8Array([11]);

  const num1Bytes = new Uint8Array(new BigUint64Array([BigInt(amountIn)]).buffer);
  const num2Bytes = new Uint8Array(new BigUint64Array([BigInt(minAmountOut)]).buffer);

  const payloadBytes = new Uint8Array([...instructionByte, ...num1Bytes, ...num2Bytes]);

  return Buffer.from(payloadBytes);
}

export function createEnqueueWithdrawalPayload(amount: number): Buffer {
  const instructionByte = new Uint8Array([12]);

  const num1Bytes = new Uint8Array(new BigUint64Array([BigInt(amount)]).buffer);

  const payloadBytes = new Uint8Array([...instructionByte, ...num1Bytes]);

  return Buffer.from(payloadBytes);
}

export function createBurnWithdrawalPayload(amount: number): Buffer {
  const instructionByte = new Uint8Array([14]);

  const payloadBytes = new Uint8Array([...instructionByte]);

  return Buffer.from(payloadBytes);
}

export function updateVaultBalancePayload(): Buffer {
  const instructionByte = new Uint8Array([25]);

  const payloadBytes = new Uint8Array([...instructionByte]);

  return Buffer.from(payloadBytes);
}

export function depositSolPayload(depositAmount: number): Buffer {
  const instructionByte = new Uint8Array([14]);
  const num1Bytes = new Uint8Array(new BigUint64Array([BigInt(depositAmount)]).buffer);
  const payloadBytes = new Uint8Array([...instructionByte, ...num1Bytes]);
  return Buffer.from(payloadBytes);
}