import { Link as ChakraLink, LinkProps as ChakraLinkProps } from "@chakra-ui/react";
import { Link as RouterLink, LinkProps as RouterLinkProps } from "react-router-dom";

type Props = ChakraLinkProps & RouterLinkProps;

export const Link = (props: Props) => {
    const isExternal = props.to.toString().startsWith("http");
    const href = isExternal ? { href: props.to.toString() } : {};

    return <ChakraLink as={isExternal ? ChakraLink : RouterLink} {...href} {...props} />;
};