import { Box, Center, Flex, Spinner, TableContainer } from "@chakra-ui/react";
import { ThemeColor } from "../../theme/palette";
import { Maybe } from "true-myth";
import { OpportunitiesTable } from "./components/OpportunitiesTable";
import { Opportunities } from "../../rest/kairos/types";

export const DeFiTable = () => {
    // FIXME: implement API
    const data: Maybe<Opportunities> = Maybe.just({opportunities: [{protocol: "Kamino", network: "Solana", multiplier: 5, tvl: 10000000, link: "app.kamino.finance"}]})

    return (
        <Box bg="#18181b" p={8} shadow="sm" borderColor={ThemeColor.KAIROS} borderWidth={0.5}>
            <Flex direction={"column"}>
                {data
                    .map(({ opportunities }) => (
                        <>
                            <TableContainer mt={2}>
                                    <OpportunitiesTable variant={"info"} data={opportunities} />
                                </TableContainer>
                        </>
                    ))
                    .unwrapOr(
                        <Center h={"57.6rem"}>
                            <Spinner />
                        </Center>,
                    )}
            </Flex>
        </Box>
    )
};