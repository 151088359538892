import { useState, useCallback } from 'react';
import * as solanaStakePool from '@solana/spl-stake-pool';
import { StakePoolAccount } from '@solana/spl-stake-pool';
import { Connection } from '@solana/web3.js';
import { PublicKey } from '@solana/web3.js';

export const useJitoSOLRate = () => {
    const [rate, setRate] = useState<number | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    const fetchConversionRate = useCallback(async (connection: Connection, amount: string): Promise<number | null> => {
        setIsLoading(true);
        setError(null);
        try {
            // Get the amount of jitoSol that will be minted for the amount of SOL
            const jitoStakePool = new PublicKey("Jito4APyf642JPZPx3hGc6WWJ8zPKtRbRs4P815Awbb")
            const stakePool: StakePoolAccount = await solanaStakePool.getStakePoolAccount(connection, jitoStakePool);
            let jitoAmountToMint = 0;
            if (stakePool.account.data.poolTokenSupply.isZero() || stakePool.account.data.totalLamports.isZero()) {
                // If the stake pool has no tokens or lamports, return the amount of SOL
                jitoAmountToMint = Number(amount);
                return jitoAmountToMint;
            } else {
                const numerator = Number(amount) * Number(stakePool.account.data.poolTokenSupply);
                jitoAmountToMint = numerator / Number(stakePool.account.data.totalLamports);
                return jitoAmountToMint;
            }
        } catch (err) {
            console.error('Error fetching JitoSOL rate:', err);
            setError('Error fetching JitoSOL rate');
            return null;
        } finally {
            setIsLoading(false);
        }
    }, []);

    const fetchRate = useCallback(async (connection: Connection) => {
        const rate = await fetchConversionRate(connection, "1"); // 1 SOL
        setRate(rate);
    }, [fetchConversionRate]);

    return { rate, fetchConversionRate, fetchRate, isLoading, error };
};
