import { ComputeBudgetProgram, Connection, Keypair, PublicKey, Transaction, TransactionInstruction } from "@solana/web3.js";
import type { WalletAdapterProps } from "@solana/wallet-adapter-base";

export async function sendTx(environment: string, connection: Connection, payer: PublicKey, sendTransaction: WalletAdapterProps['sendTransaction'], ixs: TransactionInstruction[], priorityLevel: string, signers: Keypair[] = []) {
    let accountKeys: string[] = []
    ixs.forEach(ix => ix.keys.forEach(key => accountKeys.push(key.pubkey.toString())))

    const transaction = new Transaction()

    // The endpoint does not exist for devnet. When testing on devnet we need to set the priority level to NONE
    if (environment !== "devnet") {
        const response = await fetch(connection.rpcEndpoint, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                jsonrpc: "2.0",
                id: "1",
                method: "getPriorityFeeEstimate",
                params: [
                    {
                        accountKeys: accountKeys,
                        options: { priorityLevel: priorityLevel },
                    },
                ],
            }),
        });
        const data = await response.json();

        if (data.result.priorityFeeEstimate) {
            const computeUnitLimitIx = ComputeBudgetProgram.setComputeUnitLimit({
                units: 250000
            });
            transaction.add(computeUnitLimitIx)

            const computeUnitPriceIx = ComputeBudgetProgram.setComputeUnitPrice({
                microLamports: BigInt(Math.floor(data.result.priorityFeeEstimate))
            });
            transaction.add(computeUnitPriceIx)
        } else {
            const computeUnitLimitIx = ComputeBudgetProgram.setComputeUnitLimit({
                units: 250000
            });
            transaction.add(computeUnitLimitIx)

            const computeUnitPriceIx = ComputeBudgetProgram.setComputeUnitPrice({
                microLamports: BigInt(10000000)
            });
            transaction.add(computeUnitPriceIx)
        }
    }

    ixs.forEach(ix => transaction.add(ix))

    const {
        context: { slot: minContextSlot },
        value: { blockhash, lastValidBlockHeight }
    } = await connection.getLatestBlockhashAndContext();

    transaction.recentBlockhash = blockhash
    transaction.feePayer = payer

    if (signers.length > 0) {
        signers.forEach((signer) => transaction.partialSign(signer));
    }

    const signature = await sendTransaction(transaction, connection, { minContextSlot });

    await connection.confirmTransaction({ blockhash, lastValidBlockHeight, signature });

    return signature
}