import { useState, useContext } from 'react';
import { AppConfigContext } from '../context';
import { Quest, Rank } from '../rest/kairos/types';
import { PublicKey } from '@solana/web3.js';
import mixpanel from 'mixpanel-browser';

export const useQuests = () => {
    const { apiUrl } = useContext(AppConfigContext);
    const [quests, setQuests] = useState<Quest[] | null>(null);
    const [claimedQuests, setClaimedQuests] = useState<Rank | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    const fetchQuests = async () => {
        setIsLoading(true);
        setError(null);
        try {
            const response = await fetch(`${apiUrl}/kyros/quests`);
            const data = await response.json();
            if (data) {
                const questsData: Quest[] = data;
                setQuests(questsData);
            }
        } catch (err) {
            console.error("Error fetching Quests:", err);
            setQuests(null);
            setError("Error fetching Quests");
        } finally {
            setIsLoading(false);
        }
    };

    const claimQuest = async (quest: Quest, publicKey: PublicKey | null) => {
        if (!publicKey) {
            setError("Please connect your wallet.");
            return;
        }

        mixpanel.track('Quest Claim Requested', {
            'quest_id': quest.id,
            'wallet': publicKey.toBase58(),
        });

        setIsLoading(true);
        setError(null);
        try {
            const response = await fetch(`${apiUrl}/kyros/quests/claim?quest=${quest.id}&wallet=${publicKey.toBase58()}`, {
                method: "POST",
            });
            const data = await response.json();
            if (data) {
                if ('error' in data) {
                    // Data is a KyrosError
                    setError(data.error);
                } else {
                    // Data is Rank
                    const questsData: Rank = data;
                    setClaimedQuests(questsData);
                }
            }
        } catch (err) {
            console.error("Error claiming quest:", err);
        } finally {
            setIsLoading(false);
        }
    };

    return { quests, claimedQuests, isLoading, error, fetchQuests, claimQuest };
};