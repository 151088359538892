import { extendTheme, Theme as BaseTheme } from "@chakra-ui/react";
import { Button, Heading, Link, Progress, Table, Tabs, Tag, Tooltip } from "./components";
import { config } from "./config";
import { foundations } from "./foundations";
import { palette } from "./palette";
import { semanticTokens } from "./semanticTokens";
import { styles } from "./styles";

export type Theme = BaseTheme & {
    palette: typeof palette;
    semanticTokens: typeof semanticTokens;
};

export const theme = extendTheme({
    config,
    ...foundations,
    styles,
    components: {
        Button,
        Heading,
        Link,
        Progress,
        Table,
        Tabs,
        Tag,
        Tooltip,
    },
    palette,
    semanticTokens,
}) as Theme;
