import { Flex, HStack, Link, Text, VStack } from "@chakra-ui/react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; // FontAwesomeIcon
import { faTelegram, faXTwitter } from '@fortawesome/free-brands-svg-icons';  // X (Twitter) icon
import mixpanel from "mixpanel-browser";

export const Footer: React.FC = () => {
    return (
        <Flex
            as={"footer"}
            minH={"10rem"}
            alignItems={"center"}
            justifyContent={"space-between"}
            flexDirection={{ base: "column", md: "row" }}
            py={{ base: "2.1rem", md: 0 }}
            paddingLeft={{ base: "1.8rem", sm: "2.8rem", md: "3.8rem", lg: "2rem", xl: "4.8rem" }}
            paddingRight={{ base: "1.8rem", sm: "2.8rem", md: "3.8rem", lg: "2rem", xl: "4.8rem" }}
        >
            <Text color={"gray.500"} py={"1.1rem"}>
                &copy; {new Date().getFullYear()} Kyros
            </Text>

            <VStack
                spacing={4}
                alignItems="center"
                display={{ base: "flex", md: "none" }} // VStack for mobile
                py={{ base: "1rem", sm: "0.5rem" }}
            >
                    <Link href={"/protocol-terms.pdf"} target={"_blank"} textAlign="center" onClick={() => {
                        mixpanel.track('Open Terms', {
                            'source': 'footer',
                            'type': 'protocol'
                        });
                    }}>Terms & Conditions</Link>
                    <Link href={"/website-terms.pdf"} target={"_blank"} textAlign="center" onClick={() => {
                        mixpanel.track('Open Terms', {
                            'source': 'footer',
                            'type': 'website'
                        });
                    }}>Website Terms of Use</Link>
                    <Link href={"https://docs.kyros.fi/"} target={"_blank"} textAlign="center" onClick={() => {
                        mixpanel.track('Open Docs', {
                            'source': 'footer'
                        });
                    }}>
                        Docs
                    </Link>
                <HStack spacing={4} py={"1.1rem"} justifyContent="center">
                    <Link href="https://x.com/KyrosFi" isExternal display="flex" alignItems="center" onClick={() => {
                        mixpanel.track('Social Link Click', {
                            'source': 'footer',
                            'platform': 'twitter'
                        });
                    }}>
                        <FontAwesomeIcon icon={faXTwitter} style={{ fontSize: "18px" }} />
                    </Link>
                    <Link href="https://t.me/KyrosFi" isExternal display="flex" alignItems="center" onClick={() => {
                        mixpanel.track('Social Link Click', {
                            'source': 'footer',
                            'platform': 'telegram'
                        });
                    }}>
                        <FontAwesomeIcon icon={faTelegram} style={{ fontSize: "18px" }} />
                    </Link>
                </HStack>
            </VStack>

            <HStack
                spacing={{ base: 0, sm: 8, md: 16 }}
                display={{ base: "none", md: "flex" }} // HStack for larger screens
            >
                <HStack spacing={{ base: 4, sm: 6, md: 8 }} py={"1.1rem"}>
                    <Link href={"/protocol-terms.pdf"} target={"_blank"} textAlign="center" onClick={() => {
                        mixpanel.track('Open Terms', {
                            'source': 'footer',
                            'type': 'protocol'
                        });
                    }}>Terms & Conditions</Link>
                    <Link href={"/website-terms.pdf"} target={"_blank"} textAlign="center" onClick={() => {
                        mixpanel.track('Open Terms', {
                            'source': 'footer',
                            'type': 'website'
                        });
                    }}>Website Terms of Use</Link>
                    <Link href={"https://docs.kyros.fi/"} target={"_blank"} textAlign="center" onClick={() => {
                        mixpanel.track('Open Docs', {
                            'source': 'footer'
                        });
                    }}>
                        Docs
                    </Link>
                </HStack>
                <HStack spacing={{ base: 4, sm: 5, md: 6 }} py={"1.1rem"}>
                    <Link href="https://x.com/KyrosFi" isExternal display="flex" alignItems="center" onClick={() => {
                        mixpanel.track('Social Link Click', {
                            'source': 'footer',
                            'platform': 'twitter'
                        });
                    }}>
                        <FontAwesomeIcon icon={faXTwitter} style={{ fontSize: "18px" }} />
                    </Link>
                    <Link href="https://t.me/KyrosFi" isExternal display="flex" alignItems="center" onClick={() => {
                        mixpanel.track('Social Link Click', {
                            'source': 'footer',
                            'platform': 'telegram'
                        });
                    }}>
                        <FontAwesomeIcon icon={faTelegram} style={{ fontSize: "18px" }} />
                    </Link>
                </HStack>
            </HStack>
        </Flex>
    )
};