import { useWallet } from '@solana/wallet-adapter-react';
import { useEffect } from 'react';
import mixpanel from 'mixpanel-browser';

const WalletTracker = () => {
    const { publicKey, connected } = useWallet();

    useEffect(() => {
        if (connected && publicKey) {
            const walletAddress = publicKey.toString();
            mixpanel.identify(walletAddress);
            mixpanel.people.set({ "walletAddress": walletAddress });
        }
    }, [connected, publicKey]);

    return null;
};

export default WalletTracker;