import { Box, Flex, Table, TableContainer, Tbody, Td, Th, Thead, Tr, Text, Button } from "@chakra-ui/react";
import { TableProps } from "@chakra-ui/react";
import { WithdrawalTicket } from "../../helpers/dataStructures";
import { Link } from "../Link";

interface Props extends TableProps {
    currentEpoch: number;
    tickets: WithdrawalTicket[];
    onClaimClick: (ticket: WithdrawalTicket) => void;
    claimingTicketId: string | null;
}

export const WithdrawalTable = ({ tickets, currentEpoch, onClaimClick, claimingTicketId }: Props) => {
    return (
        <Box bg="#18181b" p={8} className="mint-box">
            <Flex justifyContent="space-between" mb={6}>
                <Text fontSize="md" fontWeight="semibold" color="white">
                  PENDING (UN)STAKE
                </Text>
            </Flex>
            <Flex direction={"column"}>
                <TableContainer mt={2}>
                    <Table variant={"info"}>
                        <Thead>
                            <Tr>
                                <Th>Amount</Th>
                                <Th>Unstake</Th>
                                <Th textAlign={"end"}>
                                    Actions
                                </Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {tickets.map((ticket, index) => (
                                <Tr key={`${index}`}>
                                    <Td textColor={"white"}>{ticket.vrtAmount} kySOL</Td>
                                    <Td textColor={"white"}>
                                        <Link to={`https://explorer.solana.com/epoch/${ticket.availableEpoch}`} isExternal>
                                            Epoch {ticket.availableEpoch}
                                        </Link> 
                                        {ticket.availableEpochDate?.toLocaleString() ? ` (~${ticket.availableEpochDate.toLocaleString()})` : ''}
                                    </Td>
                                    <Td textColor={"white"} textAlign={"end"}>
                                        <Button
                                            className="claim-box-button"
                                            isDisabled={currentEpoch < ticket.availableEpoch} 
                                            onClick={() => onClaimClick(ticket)}
                                            isLoading={claimingTicketId === ticket.base.toString()}
                                        >
                                            Claim
                                        </Button>
                                    </Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                </TableContainer>
            </Flex>
        </Box>
    )
};
