import {
  ChakraProvider,
  Container,
  Box
} from "@chakra-ui/react"
import { Header, TermsModal, Footer, Notice } from "./components";
import { theme } from "./theme";
import { Navigate, Route, Routes, BrowserRouter } from "react-router-dom";
import { MintPage } from "./pages/MintPage";
import { useContext, useMemo } from 'react';
import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react';
import { WalletModalProvider } from '@solana/wallet-adapter-react-ui';
import { DeFiPage } from "./pages/DeFiPage";
import { ToastContainer } from 'react-toastify';
import { BurnPage } from "./pages/BurnPage";
import { TermsPage } from "./pages/TermsPage";
import { AppConfigContext } from "./context";
import { appConfig } from "./config";
import { WalletConnectWalletAdapter } from '@solana/wallet-adapter-walletconnect';
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import mixpanel from "mixpanel-browser";
import "./main.css";
import '@solana/wallet-adapter-react-ui/styles.css';
import 'react-toastify/dist/ReactToastify.css';
import WalletTracker from "./components/WalletTracker/WalletTracker";
import { VillagePage } from "./pages/VillagePage";

export function App() {
  const { rpcUrl, mixpanelToken, mixpanelDebug } = useContext(AppConfigContext)
  const endpoint = useMemo(() => rpcUrl, [rpcUrl]);
  const wallets = useMemo(
    () => [
      new WalletConnectWalletAdapter({
        network: WalletAdapterNetwork.Mainnet,
        options: {
            relayUrl: 'wss://relay.walletconnect.com',
            // example WC app project ID
            projectId: '6ec9a8458680821c1140d97c60871aef',
            metadata: {
                name: 'Kyros',
                description: 'Kyros',
                url: 'https://github.com/KyrosFi',
                icons: ['https://statics.solscan.io/cdn/imgs/s60?ref=68747470733a2f2f6261667962656966336a7a726376776b747535626937776375787a7233366679676f34767163746174617079377a717861776f6a78726e657877692e697066732e647765622e6c696e6b2f'],
            },
        },
    }),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [rpcUrl]
  );

  mixpanel.init(mixpanelToken, {
    debug: mixpanelDebug,
    track_pageview: true,
    persistence: "localStorage",
    ignore_dnt: true
  });

  return (
    <BrowserRouter>
      <ChakraProvider theme={theme}>
        <ConnectionProvider endpoint={endpoint}>
          <WalletProvider wallets={wallets} autoConnect>
            <WalletModalProvider>
              <AppConfigContext.Provider value={appConfig}>
                <Notice />
                <WalletTracker />
                <Header />
                <ToastContainer />
                <Container
                  display={"flex"}
                  flexDirection={"column"}
                  minH={"100vh"}
                  maxW={"container.xl"}
                  paddingX={{ base: "1.8rem", sm: "2.8rem", md: "3.8rem", lg: "2rem", xl: "4.8rem" }}
                >
                  <TermsModal />
                  <Box as={"main"} flex={1}>
                    <Routes>
                      <Route path={"*"} element={<Navigate to="/" replace />} />
                      <Route path="/" element={<MintPage />} />
                      <Route path="/redeem" element={<BurnPage />} />
                      <Route path="/defi" element={<DeFiPage />} />
                      <Route path="/terms" element={<TermsPage />} />
                      <Route path="/village" element={<VillagePage />} />
                    </Routes>
                  </Box>
                </Container>
                <Footer />
              </AppConfigContext.Provider>
            </WalletModalProvider>
          </WalletProvider>
        </ConnectionProvider>
      </ChakraProvider>
    </BrowserRouter>
  )
}