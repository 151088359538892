import { Box, Flex, Text, Button, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, VStack, HStack, Badge } from "@chakra-ui/react";
import { TableProps } from "@chakra-ui/react";
import { Claim, Quest } from "../../rest/kairos/types";
import { getColorForXpType } from "../../helpers/xp";
import mixpanel from "mixpanel-browser";
import { ThemeColor } from "../../theme/palette";
import { getAlphaColor } from "../../theme/utils";
import { Link } from "../Link";

interface Props extends TableProps {
    quests: Quest[];
    claimedQuests: Claim[];
    onClaimClick: (quest: Quest) => void;
    claimingQuestId: string | null;
}

export const Quests = ({ quests, claimedQuests, onClaimClick, claimingQuestId }: Props) => {
    const isClaimed = (quest: Quest) => {
        return claimedQuests.find(claim => claim.key === quest.id) !== undefined;
    };

    function formatTime(date: Date | undefined) {
        if (!date) {
            return "";
        }
        const newDate = new Date(date);
        return `${newDate.toLocaleDateString()} at ${newDate.toLocaleTimeString()}`;
    }

    return (
        <Box bg="#18181b" p={8} className="mint-box" maxW="100%">
            <Flex justifyContent="space-between" mb={6}>
                <Text
                    fontWeight="bold"
                    fontSize="xl"
                    color="white"
                    lineHeight="1.2"
                    mr={2}
                >
                    Quests 🧭
                </Text>
            </Flex>

            {quests.length > 0 && quests.every(quest => isClaimed(quest)) && (
                <Flex
                    color={ThemeColor.KAIROS}
                    bg={getAlphaColor(ThemeColor.KAIROS as string, 0.1)}
                    px={"0rem"}
                    py={"1.5rem"}
                    alignItems={"center"}
                    sx={{
                        a: {
                            color: getAlphaColor(ThemeColor.KAIROS as string, 1),
                            fontWeight: "bold",
                        },
                        "a:hover": {
                            color: getAlphaColor(ThemeColor.KAIROS as string, 0.6),
                        },
                    }}
                    flexDirection={"column"}
                    borderRadius={"10px"}
                    padding={"1.5rem"}
                    mb={"2rem"}
                >
                    <Text style={{ textAlign: "center" }}>
                    Your journey is complete… for the moment. Greater challenges lie ahead!
                    </Text>
                    <Text style={{ textAlign: "center" }}>Follow us on <Link to={"https://twitter.com/KyrosFi"} onClick={() => {
                            mixpanel.track('Quests Link Click', {
                                'destination': 'twitter'
                            });
                        }}>X</Link> and on <Link to={"https://t.me/KyrosFi"} onClick={() => {
                            mixpanel.track('Quests Link Click', {
                                'destination': 'telegram'
                            });
                        }}>Telegram</Link> to be among the first to embark on new quests.
                    </Text>
                </Flex>
            )}

            <Flex direction={"column"}>
                <Accordion allowToggle={claimingQuestId === null}>
                    {quests.map((quest, index) => (
                        <AccordionItem key={`${index}`} sx={{ paddingLeft: 0, paddingRight: 0 }}>
                            <h2>
                                <AccordionButton py={4}>
                                    <Box as='span' flex='1' textAlign='left'>
                                        <Text fontWeight="medium" fontSize="md" color="white">
                                            {quest.title} {quest.endDate && "🕐"}
                                        </Text>
                                        {isClaimed(quest) && (
                                            <>
                                                <HStack spacing={2}>
                                                    <Badge colorScheme="green">
                                                        Level 1
                                                    </Badge>
                                                    <Badge colorScheme="green">
                                                        Claimed
                                                    </Badge>
                                                </HStack>
                                            </>
                                        )}
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                            </h2>
                            <AccordionPanel pb={8}>
                                <VStack spacing={4} align="flex-start">
                                    <Text fontSize="sm" color="white" fontWeight="light">
                                        {quest.description}
                                    </Text>
                                    {quest.endDate && (
                                        <Text fontSize="sm" color="white" fontWeight="semibold">
                                            Ends: {formatTime(quest.endDate)}
                                        </Text>
                                    )}
                                    <Text fontSize="sm" color="white" fontWeight="semibold">
                                        Rewards:
                                    </Text>
                                    <HStack spacing={4}>
                                        {quest.rewards.map((reward, index) => (
                                            <Badge variant={claimedQuests.find(claim => claim.key === quest.id) ? "solid" : "outline"} colorScheme={getColorForXpType(reward.type)} p={2}>
                                                +{reward.amount} {reward.type.toString()}
                                            </Badge>
                                        ))}
                                    </HStack>
                                    {!isClaimed(quest) ? (
                                        <Button
                                            className="claim-box-button"
                                            onClick={() => onClaimClick(quest)}
                                            isLoading={claimingQuestId === quest.id.toString()}
                                            isDisabled={isClaimed(quest) || (quest.endDate && new Date(quest.endDate) < new Date())}
                                        >
                                            {quest.endDate && new Date(quest.endDate) < new Date() ? "Ended" : "Claim"}
                                        </Button>
                                    ) : (
                                        <Text fontSize="sm" color="gray.500" fontWeight="light">
                                            Claimed {formatTime(claimedQuests.find(claim => claim.key === quest.id)?.date)}
                                        </Text>
                                    )}
                                </VStack>
                            </AccordionPanel>
                        </AccordionItem>
                    ))}
                </Accordion>
            </Flex>
        </Box>
    )
};
