import { useState, useContext } from 'react';
import { AppConfigContext } from '../context';
import { PublicKey } from '@solana/web3.js';
import { Rank } from '../rest/kairos/types';

export const useRank = () => {
    const { apiUrl } = useContext(AppConfigContext);
    const [rank, setRank] = useState<Rank | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    const fetchRank = async (wallet: PublicKey | null) => {
        if (!wallet) {
            setRank(null);
            setIsLoading(false);
            setError("Wallet not connected");
            return;
        }

        setIsLoading(true);
        setError(null);
        try {
            const response = await fetch(`${apiUrl}/kyros/rank?wallet=${wallet.toBase58()}`);
            const data = await response.json();
            if (data) {
                const rankData: Rank = data;
                setRank(rankData);
            }
        } catch (err) {
            console.error("Error fetching Rank:", err);
            setRank(null);
            setError("Error fetching Rank");
        } finally {
            setIsLoading(false);
        }
    };

    return { rank, isLoading, error, fetchRank };
};
