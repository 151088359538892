import { useState, useEffect, useContext, useCallback } from 'react';
import { getMint } from "@solana/spl-token";
import { PublicKey } from "@solana/web3.js";
import { useConnection } from '@solana/wallet-adapter-react';
import { AppConfigContext } from '../context';

export const useTotalSupply = () => {
  const { connection } = useConnection();
  const { vrtMintAddress } = useContext(AppConfigContext);
  const kySOL = new PublicKey(vrtMintAddress);

  const [totalSupply, setTotalSupply] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const fetchTotalSupply = useCallback(async () => {
    setIsLoading(true);
    setError(null);

    try {
      const mintInfo = await getMint(connection, kySOL);
      setTotalSupply(
        (Number(mintInfo.supply) / Math.pow(10, mintInfo.decimals)).toString()
      );
    } catch (error) {
      console.error('Error fetching token total supply:', error);
      setError('Error fetching total supply');
    } finally {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, []);

  useEffect(() => {
    fetchTotalSupply();
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, []);

  return { totalSupply, isLoading, error, fetchTotalSupply };
};
