import { Box, VStack, Flex, Image, Text, PopoverHeader, PopoverContent, PopoverTrigger, Popover, Progress, HStack, PopoverFooter, Spinner } from "@chakra-ui/react";
import { ThemeColor } from "../../theme/palette";
import { getAlphaColor } from "../../theme/utils";
import { useEffect, useState, useRef } from "react";
import { useRank } from "../../hooks/useRank";
import { useWallet } from "@solana/wallet-adapter-react";
import { getProgress } from "../../helpers/xp";
import eventEmitter from '../../utils/eventEmitter';
import { Rank } from "../../rest/kairos/types";

export const RankBox = () => {
    const [isOpen, setIsOpen] = useState(false);
    const { rank, fetchRank } = useRank();
    const { publicKey } = useWallet();
    const popoverRef = useRef<HTMLDivElement>(null);
    const linkRef = useRef<HTMLAnchorElement>(null);
    const [localRank, setLocalRank] = useState<Rank | null>(null);

    useEffect(() => {
        fetchRank(publicKey);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [publicKey, isOpen]);

    useEffect(() => {
        const handleRankUpdate = (updatedRank: Rank) => {
            setLocalRank(updatedRank);
        };

        eventEmitter.on('rankUpdated', handleRankUpdate);

        // Clean up the event listener on component unmount
        return () => {
            eventEmitter.off('rankUpdated', handleRankUpdate);
        };
    }, []);

    useEffect(() => {
        if (rank) {
            setLocalRank(rank);
        }
    }, [rank]);

    // Dismiss popover when clicking outside
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                popoverRef.current &&
                !popoverRef.current.contains(event.target as Node) &&
                !(linkRef.current && linkRef.current.contains(event.target as Node))
            ) {
                setIsOpen(false);
            }
        };

        if (isOpen) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [isOpen]);

    const handleToggle = (isOpen: boolean) => {
        setIsOpen(!isOpen);
    };

    return (
        <Popover isOpen={isOpen && localRank != null} autoFocus={false} returnFocusOnClose={false}>
            <PopoverTrigger>
                <Box
                    as="a"
                    ref={popoverRef}
                    flex="1"
                    p={4}
                    borderRadius="10px"
                    backgroundColor="whiteAlpha.100"
                    height="50px"
                    transition="all 0.2s"
                    _hover={{
                        backgroundColor: "whiteAlpha.200",
                        cursor: "pointer"
                    }}
                    border={"0.5px solid #5C5C5C"}
                    onClick={() => handleToggle(isOpen)}
                >
                    {localRank ?
                        <Flex alignItems="center" gap={3} height="100%">
                            <Image src={`/kyros/${localRank.rank.title.toLowerCase()}.png`} boxSize="30px" />
                            <VStack
                                align="flex-start"
                                spacing={1}
                                display={{ base: "none", xl: "flex" }}
                            >
                                <Text fontSize="md" fontWeight="semibold" color="white">
                                    {localRank.rank.title}
                                </Text>
                            </VStack>
                        </Flex>
                        : <Spinner />}
                </Box>
            </PopoverTrigger>
            <PopoverContent ref={popoverRef} border={"0.5px solid #5C5C5C"} borderRadius="8px" backgroundColor={"#0F1114"} >
                {localRank ? (
                    <>
                        <PopoverHeader>
                            <Box display={{ base: "block", xl: "none" }} mb={3} mt={1} textAlign="center">
                                <Text fontSize="md" fontWeight="semibold" color="white">
                                    {localRank.rank.title}
                                </Text>
                            </Box>
                            <HStack spacing={4} width="100%">
                                <Image src="/kyros/book.png" boxSize="35px" flexShrink={0} />
                                <VStack align="flex-start" spacing={2} width="100%">
                                    <Text fontSize="md" fontWeight="semibold" color="white">
                                        XP: {localRank.xp} / {localRank.rank.maxXp}
                                    </Text>
                                    <Progress value={getProgress(localRank.xp, localRank.rank.minXp, localRank.rank.maxXp)} size="lg" colorScheme="gray" width="100%" height="5px"
                                        sx={{
                                            '& > div': {
                                                backgroundColor: '#5EF4F3'
                                            },
                                            backgroundColor: '#2D2D2D'
                                        }} />
                                </VStack>
                            </HStack>
                        </PopoverHeader>
                        <PopoverFooter>
                            <Text fontSize="sm" color="white"
                                sx={{
                                    a: {
                                        color: getAlphaColor(ThemeColor.KAIROS as string, 1),
                                        fontWeight: "bold",
                                    },
                                    "a:hover": {
                                        color: getAlphaColor(ThemeColor.KAIROS as string, 0.6),
                                    },
                                }}>
                                Head to <a href="/village" ref={linkRef} onClick={(e) => { e.stopPropagation(); handleToggle(isOpen); }}>the Village</a> for more information.
                            </Text>
                        </PopoverFooter>
                    </>
                ) : (
                    <Spinner />
                )}
            </PopoverContent>
        </Popover>
    )
}