import {
    Button,
    Checkbox,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Stack,
    Text,
    useDisclosure,
    VStack,
} from "@chakra-ui/react";
import Cookies from "js-cookie";
import React from "react";
import { Link } from "../Link";
import mixpanel from "mixpanel-browser";

const TERMS_COOKIE_NAME = "terms-accepted-date";

export const TermsModal = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [accepted, setAccepted] = React.useState(!!Cookies.get(TERMS_COOKIE_NAME));

    const onEnter = () => {
        Cookies.set(TERMS_COOKIE_NAME, new Date().toUTCString(), { expires: 3650 });
        onClose();
    };

    React.useEffect(() => {
        !accepted && onOpen();
    }, [accepted, onOpen]);

    return (
        <Modal
            size={"6xl"}
            closeOnOverlayClick={false}
            isOpen={isOpen}
            onClose={onClose}
            autoFocus={false}
            scrollBehavior={"inside"}
            isCentered
        >
            <ModalOverlay />
            <ModalContent mt={{ base: 0, sm: 8, md: 16 }} bg={"#18181b"} color={"white"}>
                <ModalHeader>Warning</ModalHeader>
                <ModalBody pt={0}>
                    <Stack>
                        <Text>
                            No representation or warranty is made concerning any aspect of the Kyros Protocol, including its suitability, quality, availability, accessibility, accuracy or safety. As more fully explained in the Terms of Use (available{" "}
                            <Link to={"/protocol-terms.pdf"} textColor={"#5FF4F4"} target={"_blank"} onClick={() => {
                                mixpanel.track('Open Terms', {
                                    'source': 'terms_modal',
                                    'type': 'protocol'
                                });
                            }}>
                                here
                            </Link>
                            ), your access to and use of the Kyros Protocol through this Interface is entirely at your own risk and could lead to substantial losses, for which you take full responsibility.
                        </Text>
                        <Text>
                        This Interface is not available to residents of Belarus, Burundi, the Central African Republic, the Democratic Republic of Congo, the Democratic People’s Republic of Korea, the temporarily occupied regions of Ukraine, Cuba, Iran, Libya, the People’s Republic of China, the Russian Federation, Somalia, Sudan, South Sudan, Syria, the United States of America (including its overseas territories), Venezuela, Yemen, and Zimbabwe or any other jurisdiction in which accessing or using the Kyros Protocol is prohibited (“Prohibited Jurisdictions”). In using this Interface, you confirm that you are not located in, incorporated or otherwise established in, resident, or a citizen of a Prohibited Jurisdiction.
                        </Text>
                    </Stack>
                </ModalBody>
                <ModalFooter>
                    <VStack spacing={4} w={"100%"}>
                        <Checkbox isChecked={accepted} onChange={({ target }) => setAccepted(target.checked)}>
                            I confirm that I have read, understand and accept the{" "}
                            <Link to={"/protocol-terms.pdf"} textColor={"#5FF4F4"} target={"_blank"} onClick={() => {
                                mixpanel.track('Open Terms', {
                                    'source': 'terms_modal',
                                    'type': 'protocol'
                                });
                            }}>
                                Terms of Use
                            </Link>
                        </Checkbox>
                        <Button onClick={onEnter} isDisabled={!accepted} bg={"#5FF4F4"} _hover={{ bg: "#5FF4F4" }} color={"black"}>
                            Enter
                        </Button>
                    </VStack>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};