/* Opportunities */
export interface Opportunities {
    opportunities: Opportunity[];
}

export interface Opportunity{
    protocol: string;
    network: string;
    multiplier: number;
    tvl: number;
    link: string;
}

/* Quests */
export interface Quest {
    id: number;
    title: string;
    description: string;
    location: string;
    rewards: XpReward[];
    partner?: string;
    partnerImage?: string;
    startDate?: Date;
    endDate?: Date;
}

export interface XpReward {
    type: XpType,
    amount: number
}

export enum XpType {
    Loyalty = "loyalty",
    Defense = "defense",
    Attack = "attack"
}

/* Rank */

export interface Rank {
    rank: RankLevel;
    xp: number;
    loyalty: number;
    defense: number;
    attack: number;
    claims: Claim[];
  }
  
  export interface RankLevel {
    id: number;
    title: string;
    description: string;
    image: string;
    minXp: number;
    maxXp: number;
    requirements: XpReward[];
}

export interface Claim {
    key: number;
    date: Date;
}

export interface KyrosError {
    error: string;
}