import { useState } from 'react';
import { LAMPORTS_PER_SOL, PublicKey } from "@solana/web3.js";
import { useConnection } from '@solana/wallet-adapter-react';

export const useNativeBalance = () => {
  const { connection } = useConnection();

  const [balance, setBalance] = useState<string | null>("0");
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const fetchNativeBalance = async (publicKey: PublicKey | null) => {
    setIsLoading(true);
    setError(null);

    try {
      if (!publicKey) {
        setError('Wallet not connected');
        return;
      }

      const lamports = await connection.getBalance(publicKey);
      const solBalance = lamports / LAMPORTS_PER_SOL;

      setBalance(solBalance.toString());
    } catch (error) {
      console.error('Error fetching token balance:', error);
      setBalance('0');
    } finally {
      setIsLoading(false);
    }
  };

  return { balance, isLoading, error, fetchNativeBalance };
};