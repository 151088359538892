export interface AppConfig {
    environment: string;
    rpcUrl: string;
    priorityFeeLevel: string;
    jitoSolMintAddress: string;
    vrtMintAddress: string;
    supportedTokenMintAddress: string;
    vaultAddress: string;
    feeWalletAddress: string;
    vaultProgramAddress: string;
    vaultConfigAddress: string;
    restakingFeeWalletAddress: string;
    vaultMaxCap: number;
    vaultPaused: boolean;
    mixpanelToken: string;
    mixpanelDebug: boolean;
    apiUrl: string;
}

export const appConfig: AppConfig = {
    environment: process.env.REACT_APP_ENVIRONMENT || '',
    rpcUrl: process.env.REACT_APP_RPC_URL || '',
    priorityFeeLevel: process.env.REACT_APP_PRIORITY_FEE_LEVEL || '',
    jitoSolMintAddress: process.env.REACT_APP_JITO_SOL_MINT_ADDRESS || '',
    vrtMintAddress: process.env.REACT_APP_VRT_MINT_ADDRESS || '',
    supportedTokenMintAddress: process.env.REACT_APP_SUPPORT_TOKEN_MINT_ADDRESS || '',
    vaultAddress: process.env.REACT_APP_VAULT_ADDRESS || '',
    feeWalletAddress: process.env.REACT_APP_FEE_WALLET_ADDRESS || '',
    vaultProgramAddress: process.env.REACT_APP_VAULT_PROGRAM_ADDRESS || '',
    vaultConfigAddress: process.env.REACT_APP_VAULT_CONFIG_ADDRESS || '',
    restakingFeeWalletAddress: process.env.REACT_APP_RESTAKING_FEE_WALLET_ADDRESS || '',
    vaultMaxCap: Number(process.env.REACT_APP_VAULT_MAX_CAP) || 0,
    vaultPaused: process.env.REACT_APP_VAULT_PAUSED ? process.env.REACT_APP_VAULT_PAUSED === 'true' : true,
    mixpanelToken: process.env.REACT_APP_MIXPANEL_TOKEN || '',
    mixpanelDebug: process.env.REACT_APP_MIXPANEL_DEBUG ? process.env.REACT_APP_MIXPANEL_DEBUG === 'true' : false,
    apiUrl: process.env.REACT_APP_API_URL || '',
};
