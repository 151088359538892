import { useState, useCallback, useContext } from "react";
import { PublicKey, TransactionInstruction } from "@solana/web3.js";
import {
    getAssociatedTokenAddress,
    createAssociatedTokenAccountIdempotentInstruction,
    TOKEN_PROGRAM_ID,
} from "@solana/spl-token";
import { LAMPORTS_PER_SOL } from "@solana/web3.js";
import { useAnchorWallet, useConnection, useWallet } from "@solana/wallet-adapter-react";
import { AppConfigContext } from "../context";
import { createBurnWithdrawalPayload } from "../helpers/payloads";
import { WithdrawalTicket } from "../helpers/dataStructures";
import {sendTx} from "./utils/utils";
import mixpanel from "mixpanel-browser";

export const useBurnWithdrawal = () => {
    const { connection } = useConnection();
    const { publicKey, sendTransaction } = useWallet();
    const wallet = useAnchorWallet()
    const { environment, priorityFeeLevel, vrtMintAddress, supportedTokenMintAddress, vaultAddress, vaultProgramAddress, vaultConfigAddress, feeWalletAddress, restakingFeeWalletAddress } = useContext(AppConfigContext)

    const vrtMint = new PublicKey(vrtMintAddress)
    const supportedTokenMint = new PublicKey(supportedTokenMintAddress)
    const vaultConfig = new PublicKey(vaultConfigAddress)
    const vault = new PublicKey(vaultAddress)
    const vaultProgram = new PublicKey(vaultProgramAddress)
    const feeWallet = new PublicKey(feeWalletAddress)
    const staker = publicKey
    const restakingFeeWallet = new PublicKey(restakingFeeWalletAddress)

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);

    const burnWithdrawal = useCallback(async (ticketInfo: WithdrawalTicket | null) => {
        if (!staker) {
            setError("Please connect your wallet.")
            return false
        }

        if (!wallet) {
            setError("Please connect your wallet.")
            return false
        }

        if (!ticketInfo) {
            setError("Please select a ticket to claim.")
            return false
        }

        mixpanel.track('Burn Withdrawal', {
            'amount': ticketInfo.vrtAmount,
            'wallet': wallet.publicKey?.toBase58(),
        })

        setLoading(true);

        let ixs: TransactionInstruction[] = []
        const base = new PublicKey(ticketInfo.base)

        try {
            // 1.1 PDA for ticket account
            const seeds = [
                Buffer.from("vault_staker_withdrawal_ticket"),
                vault.toBuffer(),
                base.toBuffer(),
            ];
            const [ticket] = PublicKey.findProgramAddressSync(seeds, vaultProgram);

            // 1.2. ATA for VRT / ticket
            const ticketVrtAta = await getAssociatedTokenAddress(
                vrtMint,
                ticket,
                true
            );

            const createIdempotentTicketVrtAtaIx = createAssociatedTokenAccountIdempotentInstruction(
                staker,
                ticketVrtAta,
                ticket,
                vrtMint
            );
            ixs.push(createIdempotentTicketVrtAtaIx);

            // 2. ATA for supported token / staker
            const stakerSupportedTokenAta = await getAssociatedTokenAddress(
                supportedTokenMint,
                staker
            );

            const createIdempotentStakerSupportedTokenAtaIx = createAssociatedTokenAccountIdempotentInstruction(
                staker,
                stakerSupportedTokenAta,
                staker,
                supportedTokenMint
            )
            ixs.push(createIdempotentStakerSupportedTokenAtaIx)

            // 3. ATA for VRT / fee wallet
            const feeWalletVrtAta = await getAssociatedTokenAddress(
                vrtMint,
                feeWallet,
                true
            );

            const createIdempotentFeeWalletVrtAtaIx = createAssociatedTokenAccountIdempotentInstruction(
                staker,
                feeWalletVrtAta,
                feeWallet,
                vrtMint
            )
            ixs.push(createIdempotentFeeWalletVrtAtaIx)

            // 4. ATA for supported token / vault
            const vaultSupportedTokenAta = await getAssociatedTokenAddress(
                supportedTokenMint,
                vault,
                true
            );

            const createIdempotentVaultSupportedTokenAtaIx = createAssociatedTokenAccountIdempotentInstruction(
                staker,
                vaultSupportedTokenAta,
                vault,
                supportedTokenMint
            )
            ixs.push(createIdempotentVaultSupportedTokenAtaIx)

            // 5. ATA for VRT / restaking fee wallet
            const restakingFeeWalletVrtAta = await getAssociatedTokenAddress(
                vrtMint,
                restakingFeeWallet,
                true
            );

            const createIdempotentRestakingFeeWalletVrtAtaIx = createAssociatedTokenAccountIdempotentInstruction(
                staker,
                restakingFeeWalletVrtAta,
                restakingFeeWallet,
                vrtMint
            )
            ixs.push(createIdempotentRestakingFeeWalletVrtAtaIx)

            // 6. Enqueue withdrawal
            const burnWithdrawalTicketIx = new TransactionInstruction({
                keys: [
                    { pubkey: vaultConfig, isSigner: false, isWritable: false },  // Config account
                    { pubkey: vault, isSigner: false, isWritable: true },    // Vault account
                    { pubkey: vaultSupportedTokenAta, isSigner: false, isWritable: true },  // Vault token account
                    { pubkey: vrtMint, isSigner: false, isWritable: true }, // VRT Mint
                    { pubkey: staker, isSigner: true, isWritable: true }, // Staker
                    { pubkey: stakerSupportedTokenAta, isSigner: false, isWritable: true }, // Staker token account
                    { pubkey: ticket, isSigner: false, isWritable: true }, // Vault staker withdrawal ticket
                    { pubkey: ticketVrtAta, isSigner: false, isWritable: true }, // Vault staker withdrawal ticket token account
                    { pubkey: feeWalletVrtAta, isSigner: false, isWritable: true }, // Vault fee token account
                    { pubkey: restakingFeeWalletVrtAta, isSigner: false, isWritable: true }, // Program fee token account
                    { pubkey: TOKEN_PROGRAM_ID, isSigner: false, isWritable: false }, // Token program
                    { pubkey: new PublicKey("11111111111111111111111111111111"), isSigner: false, isWritable: false }, // System program
                    { pubkey: vaultProgram, isSigner: false, isWritable: true }  // Burn signer
                ],
                programId: vaultProgram,
                data: createBurnWithdrawalPayload(ticketInfo.vrtAmount * LAMPORTS_PER_SOL)
            });
            ixs.push(burnWithdrawalTicketIx);

            return await sendTx(environment,connection, staker, sendTransaction, ixs, priorityFeeLevel)
        } catch (err) {
            setError(`Something went wrong with the transaction: ${err}`)
            return false
        } finally {
            setLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [staker, wallet]);

    return { burnWithdrawal, loading, error };
};
